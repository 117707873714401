<template>
  <div>
    <v-btn
      bottom
      class="secondary"
      dark
      fab
      fixed
      right
      id="createUserModalBtn"
      @click="dialog = !dialog"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent width="800px">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-title class="primary darken-2 white--text justify-center">
            Create User
          </v-card-title>

          <v-container>
            <v-row class="mx-2">
              <v-col class="align-center justify-space-between" cols="12">
                <v-row align="center" class="mr-0">
                  <v-col cols="6">
                    <v-text-field
                      id="firstName"
                      prepend-icon="mdi-account"
                      label="First Name"
                      v-model="firstname"
                      :rules="[required('First name')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      id="lastName"
                      prepend-icon="mdi-account"
                      label="Last Name"
                      v-model="lastname"
                      :rules="[required('Last name')]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-select
                  prepend-icon="mdi-domain"
                  :items="sections"
                  label="Department"
                  v-model="department"
                  :rules="[required('Department')]"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  prepend-icon="mdi-menu"
                  item-text="name"
                  item-value="value"
                  :items="roles"
                  label="Roles"
                  v-model="assignedRole"
                  :rules="[required('Role')]"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  prepend-icon="mdi-mail"
                  label="Email"
                  v-model="email"
                  :rules="[required('Email'), emailFormat()]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  type="tel"
                  prepend-icon="mdi-phone"
                  label="Extension No."
                  v-model="extension"
                  :rules="[required('Extension No.'), numberFormat()]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  prepend-icon="mdi-map-marker"
                  :items="regions"
                  label="Region"
                  v-model="region"
                  :rules="[required('Region')]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <div class="ma-auto pb-5">
              <v-btn
                id="inviteUserBtn"
                rounded
                class="ma-5 pa-4"
                color="secondary"
                @click="inviteUser"
                :dialogInvite="dialogInvite"
                :loading="loading"
                :disabled="!valid"
              >
                Invite User
                <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
              </v-btn>

              <v-btn
                rounded
                id="clearFormBtn"
                class="ma-5 pa-4"
                color=""
                @click="clear"
                dark
              >
                Clear
                <v-icon dark right class="ml-2"> mdi-format-clear </v-icon>
              </v-btn>

              <v-btn
                id="closeModalBtn"
                rounded
                class="ma-5 pa-4"
                color="red"
                @click="userCancel"
                dark
              >
                Cancel
                <v-icon dark right> mdi-close </v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import validation from '@/utils/Validations'
import axios from 'axios'
export default {
  data: () => ({
    valid: false,
    firstname: '',
    lastname: '',
    department: '',
    assignedRole: '',
    email: '',
    region: null,
    extension: '',
    dialog: false,
    loading: false,
    dialogInvite: false,
    alert: false,
    sections: ['Motor Claims', 'Non-Motor Claims', 'Legal'],
    regions: [],
    roles: [
      { name: 'Admin', value: 'admin' },
      { name: 'Section Head', value: 'sectionHead' },
      { name: 'Claim Officer', value: 'claimOfficer' },
      { name: 'Claim Analyst', value: 'claimAnalyst' },
      { name: 'Internal Assessor', value: 'internalAssessor' },
      { name: 'External Assessor', value: 'externalAssessor' },
      { name: 'Technician', value: 'technician' },
    ],
    ...validation,
  }),

  mounted: function () {
    this.fetchRegions()
  },

  methods: {
    inviteUser() {
      this.dialogInvite = true
      this.loading = true

      const inviteData = {
        name: `${this.firstname} ${this.lastname}`,
        email: this.email,
        department: this.department,
        role: this.assignedRole,
        extension: this.extension,
        region: this.region,
      }

      axios
        .post('/motor-assessment/api/users/invite/BACK_OFFICE', inviteData)
        .then(() => {
          this.$toasted.success(
            `You have successfully added , ${inviteData.name} as ${inviteData.role}`,
            {
              duration: 3000,
            }
          )
          this.userCancel()
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
        })
      this.loading = false
    },

    fetchRegions() {
      axios
        .get('/motor-assessment/api/regions')
        .then((res) => {
          let regions = res.data.data
          this.regions = regions.map((region) => region.name)
        })
        .catch((err) => {
          this.$store.commit(
            'SNACKBAR',
            {
              state: 'failed',
              message: `${err.message}`,
              variant: 'error',
            },
            { root: true }
          )
        })
    },

    userCancel() {
      this.dialog = false
      this.$refs.form.reset()
    },

    clear() {
      this.$refs.form.reset()
    },
  },
}
</script>
